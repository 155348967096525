// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useNotifications from '@/composables/useNotifications'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
import esLocale from '@fullcalendar/core/locales/es'
import useCommon from '@/views/organization/useCommon'
import useCommonTodo from '@/views/apps/todo/useCommonTodo'
import awsConnection from '@/views/habit/aws';
import { useRouter } from '@core/utils/utils'
import realmConnection from '@/views/habit/realm'

export default function userCalendar() {
  // Use toast/notifications
  const toast = useToast()
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null

  const isEventHandlerSidebarActive = ref(false)
  const isCalendarOverlaySidebarActive = ref(false)
  const isLoading = ref(true)
  const isInstance = ref(false)
  const isOnline = computed(() => store.state.app.isOnline)

  const { route } = useRouter()
  const routeQueryEventId = route.value.query.originId

  const eventsForStats = ref([]);
  const locationFilter = ref([]);
  const roleFilter = ref([]);
  const workerFilter = ref([]);
  const zoneFilter = ref([]);
  const agencyFilter = ref([]);

  const now = new Date()
  const endDateTime = new Date()
  now.setHours(12, 0, 0, 0)
  endDateTime.setHours(13, 0, 0, 0)
  
  const monthFilter = ref(now.getMonth())
  const yearFilter = ref(now.getFullYear())

  const calendarMonth = ref(null)
  const calendarYear = ref(null)

  const widthBreakpoint = ref(768)

  const getUserData = store.state?.userStore?.userData
  const userId = getUserData.worker_id != null ? getUserData.worker_id.$oid : null
  const clientId = getUserData.client.$oid
  const username = getUserData.username;
  const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality
  const collection = 'event'

  const { handleError, fetchAndStoreEvents, getMetadataForDropDown, metadataNotMapped, createImprovements, updateCommitmentOfEvent } = useCommon()
  const { sendEmailInstance, sendEmailImprovements, addOriginIdEvent } = useCommonTodo()
  const { singleUpload, getMessagesFromQueue } = awsConnection()
  const { getItemsWithAggregate, createItem, updateItem, updateItems, ObjectId } = realmConnection()

  onMounted(async () => {
    calendarApi = refCalendar.value.getApi()
    getMetadataForDropDown({ category: "improvement", option: "notMapped" })

    // Open Calendar Event Handler if there is an event id in the route query
    if (routeQueryEventId) {
      try {
        const query = { _id: ObjectId(routeQueryEventId) }

        const pipeline = [
          { $match: query },
          {
            $lookup: {
              from: 'worker',
              localField: 'attendee',
              foreignField: '_id',
              pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'attendee'
            }
          },
          {
            $lookup: {
              from: 'process',
              localField: 'process',
              foreignField: '_id',
              pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'process'
            }
          },
          {
            $lookup: {
              from: 'commitment',
              localField: 'commitment',
              foreignField: '_id',
              pipeline: [ { $project: { completed: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'commitment'
            }
          },
          {
            $lookup: {
              from: 'confirmation',
              localField: 'confirmation',
              foreignField: '_id',
              pipeline: [
                { $project: { pending: 1, supervisor: 1 } },
                {
                  $lookup: {
                    from: 'worker',
                    localField: 'supervisor',
                    foreignField: '_id',
                    pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                    as: 'supervisor',
                  },
                },
                { $addFields: { _id: { $toString: "$_id" }, supervisor: { $arrayElemAt: ["$supervisor", 0] } } }
              ],
              as: 'confirmation'
            }
          },
          {
            $lookup: {
              from: 'improvement',
              localField: 'improvements',
              foreignField: '_id',
              pipeline: [
                {
                  $lookup: {
                    from: 'worker',
                    localField: 'assignee',
                    foreignField: '_id',
                    pipeline: [ { $project: { name: 1, email: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                    as: 'assignee',
                  },
                },
                { $addFields: { _id: { $toString: "$_id" }, origin_id: { $toString: "$origin_id" }, assistance: { $toString: "$assistance" }, assignee: { $arrayElemAt: ["$assignee", 0] }, subscribers: { $map: { input: "$subscribers", as: "id", in: { $toString: "$$id" } } } } }
              ],
              as: 'improvements'
            }
          },
          { $addFields: { _id: { $toString: "$_id" }, assistance: { $toString: "$assistance" }, attendee: { $arrayElemAt: ["$attendee", 0] }, process: { $arrayElemAt: ["$process", 0] }, confirmation: { $arrayElemAt: ["$confirmation", 0] }, commitment: { $arrayElemAt: ["$commitment", 0] }, participants: { $map: { input: "$participants", as: "id", in: { $toString: "$$id" } } } } },
        ]
      
        const items = await getItemsWithAggregate({ collection, pipeline })

        const e = items[0]

        event.value = {
          id: e._id,
          title: setEventTitle(e),
          start: e.start,
          end: e.end,
          allDay: e.allDay,
          extendedProps: {
            calendar: e.extendedProps.calendar,
            process: e.process,
            attendee: e.attendee,
            confirmation: e.confirmation,
            isInstance: e.isInstance,
            isBehaviourEvaluation: e.isBehaviourEvaluation,
            commitment: e.commitment,
            metadata: e.metadata,
            improvements: e.improvements,
            participants: e.participants,
            assistance: e.assistance,
          },
        }
        isEventHandlerSidebarActive.value = true
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.event_fetch_error'))
      }
    }

    // Fetch instances received by email
    if (commitmentFunctionality) getMessagesFromQueue()
  })

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    Pendientes: 'bg-light-warning',
    Vencidas: 'bg-light-danger',
    Realizadas: 'bg-light-success',
    Futuras: 'bg-light-info',
    Propias: 'bg-light-secondary',
    enEspera: 'bg-secondary text-white',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    title: i18n.t('metadata.instance'),
    start: now,
    end: endDateTime,
    allDay: false,
    url: '',
    extendedProps: {
      calendar: '',
      guests: [],
      location: '',
      description: '',
      process: '',
      attendee: '',
      link: '',
      confirmation: '',
      isInstance: false,
      isBehaviourEvaluation: false,
      commitment: '',
      metadata: [],
      improvements: [],
      participants: [],
      assistance: '',
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  watch(metadataNotMapped, val => {
    if (val && val.length) {
      const filteredMetadata = []
      val.forEach(e => {
        // Event metadata only has instance and instance_leader
        if (e.name === "instance" || e.name === "instance_leader") {
          // Set default instance leader
          if (e.name === "instance_leader") e.answer = username
          filteredMetadata.push(e)
        }
      })
      blankEvent.extendedProps.metadata = filteredMetadata
      if (!event.value.id) clearEventData()
    }
  })

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'primary',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('message.Event_updated'),
        icon: 'CheckIcon',
        variant: 'primary',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)
    

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }

    // Custom code to update process and title
    existingEvent.setExtendedProp('process', updatedEventData.process)
    existingEvent.setProp('title', setEventTitle(updatedEventData))
  }

  const setEventTitle = (eventData) => {
    if (eventData.isInstance) return eventData.title
    const attendee = eventData.attendee?.name || ''
    const process = eventData.process?.name ? eventData.process.name : i18n.t('Confirmation')

    return eventData.isBehaviourEvaluation
      ? `${attendee} - ${eventData.title}`
      : attendee
        ? `${attendee} - ${process}`
        : process
  }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('message.Event_removed'),
        icon: 'TrashIcon',
        variant: 'primary',
      },
    })
    calendarApi.getEventById(eventId).remove()
    isEventHandlerSidebarActive.value = false
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: { calendar, guests, location, description, process, attendee, link, confirmation, isInstance, isBehaviourEvaluation, commitment, metadata, improvements, participants, assistance },
      allDay,
    } = eventApi

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        calendar,
        guests,
        location,
        description,
        process,
        attendee,
        link,
        confirmation,
        isInstance,
        isBehaviourEvaluation,
        commitment,
        metadata,
        improvements,
        participants,
        assistance
      },
      allDay,
    }
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = async eventData => {
    try {
      const newEvent = {
        client_id: ObjectId(clientId),
        isInstance: true,
        extendedProps: { calendar: 'Pendientes' },
        start: eventData.start instanceof Date ? eventData.start : new Date(eventData.start),
        end: eventData.end instanceof Date ? eventData.end : new Date(eventData.end),
        title: eventData.title,
        organizer: ObjectId(userId)
      }

      if (newEvent.end <= newEvent.start) {
        newEvent.end = new Date(newEvent.start)
        newEvent.end.setHours(newEvent.end.getHours() + 1)
      }

      if (eventData.extendedProps.metadata?.length) {
        newEvent.metadata = eventData.extendedProps.metadata.map(m => ({
          name: m.name,
          type: m.type,
          answer: m.answer,
        }))
      }

      if (eventData.extendedProps.participants?.length) {
        newEvent.participants = eventData.extendedProps.participants.map(e => ObjectId(e))
      }

      if (eventData.extendedProps.improvements?.length) {
        try {
          const newImprovementIds = await createImprovements(eventData.extendedProps.improvements, "event", null)
          if (newImprovementIds?.length) {
            newEvent.improvements = newImprovementIds.map(e => ObjectId(e))
          }
        } catch (error) {
          console.log(error)
        }
      }

      const { insertedId } = await createItem({ collection, payload: newEvent })
      if (!insertedId) throw new Error('Item not created')
      
      showSuccessMessage(i18n.t('message.Event_created'))
      
      const eventId = insertedId.toString()
      
      // Refetch events
      refetchEvents()

      // Send email to participants with the details of the instance created
      if (eventData.extendedProps.participants?.length) {
        sendEmailInstance(eventData, eventId, 'add')
      }

      // Get event to obtain the improvements ids and update each one to add origin and origin_id from event
      addOriginIdEvent(eventId)
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.event_create_error'))
    }
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = async (eventData, improvementsToUpdate = [], improvementsToDelete = []) => {
    // Copy improvements (if any) in another variable to use them later
    const allImprovements = eventData.extendedProps.improvements
      ? [...eventData.extendedProps.improvements, ...improvementsToDelete]
      : [...improvementsToDelete]

    try {
      const query = { _id: ObjectId(eventData.id) }

      const updatedEvent = {
        attendee: eventData.extendedProps.attendee ? ObjectId(eventData.extendedProps.attendee._id) : null,
        process: eventData.extendedProps.process ? ObjectId(eventData.extendedProps.process._id) : null
      }

      if (eventData.extendedProps.metadata) {
        updatedEvent.metadata = eventData.extendedProps.metadata
      }

      if (eventData.extendedProps.participants) {
        updatedEvent.participants = eventData.extendedProps.participants.map(e => ObjectId(e))
      }

      let newImprovementIds = null
      if (eventData.extendedProps.improvements?.length) {
        let allImprovementIds = eventData.extendedProps.improvements.filter(e => e._id).map(e => e._id)
        const newImprovements = eventData.extendedProps.improvements.filter(e => !e._id)
        try {
          newImprovementIds = await createImprovements(newImprovements, "event", null, false)
          if (newImprovementIds?.length) allImprovementIds = [...allImprovementIds, ...newImprovementIds]
        } catch (error) {
          console.log(error)
        }
        if (allImprovementIds.length) updatedEvent.improvements = allImprovementIds.map(e => ObjectId(e))
      } else {
        updatedEvent.improvements = []
      }

      updatedEvent.start = eventData.start instanceof Date ? eventData.start : new Date(eventData.start)
      updatedEvent.end = eventData.end instanceof Date ? eventData.end : new Date(eventData.end)

      if (!commitmentFunctionality || eventData.end <= eventData.start) {
        updatedEvent.end = new Date(updatedEvent.start)
        updatedEvent.end.setHours(updatedEvent.end.getHours() + 1 )
      }

      // Set calendar status
      updatedEvent.extendedProps = { calendar: eventData.extendedProps.calendar }
      if (eventData.extendedProps.calendar !== 'Realizadas') {
        const now = new Date()
        const firstDayOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)
        
        updatedEvent.start.getTime() < now.getTime() ? updatedEvent.extendedProps.calendar = 'Vencidas' : null
        updatedEvent.start.getTime() >= now.getTime() ? updatedEvent.extendedProps.calendar = 'Pendientes' : null
        updatedEvent.start.getTime() >= firstDayOfNextMonth.getTime() ? updatedEvent.extendedProps.calendar = 'Futuras' : null
      }
      
      updatedEvent.modifiedAt = new Date()

      const action = { $set: updatedEvent }

      await updateItem({ collection, query, action })

      updatedEvent.id = eventData.id

      const propsToUpdate = ['id']
      const extendedPropsToUpdate = ['calendar']

      updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
      refetchEvents()

      // Send email to assignee and subscribers with the details of the improvements created and updated
      if (allImprovements.length) {
        sendEmailImprovements(allImprovements, null, improvementsToUpdate, improvementsToDelete)
      }

      // Send email to participants with the details of the instance updated
      if (eventData.extendedProps.participants?.length && updatedEvent.id) {
        sendEmailInstance(eventData, updatedEvent.id, 'update')
      }

      // Update commitment asociated with event
      if (eventData.extendedProps.commitment?._id) {
        updateCommitmentOfEvent(eventData.extendedProps.commitment._id, eventData.start)
      }
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.event_update_error'))
    }
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = async ({ eventData, deletedJustification, improvementsToDelete }) => {
    const action = { $set: { deleted: true } }

    try {
      // Delete improvements associated with event
      if (eventData.extendedProps.improvements?.length) {
        const updateQuery = { _id: { $in: eventData.extendedProps.improvements.map(e => {
          if (!e._id) return null
          return ObjectId(e._id)
        }) } }

        await updateItems({ collection: 'improvement', query: updateQuery, action })
      }

      // Delete improvements already flagged for deletion
      if (improvementsToDelete?.length) {
        const updateQuery = { _id: { $in: improvementsToDelete.map(e => ObjectId(e._id)) } }
        
        await updateItems({ collection: 'improvement', query: updateQuery, action })
      }
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.improvement_update_error'))
    }

    try {
      // Delete event
      const query = { _id: ObjectId(eventData.id) }
      const eventAction = { $set: {
        deleted: true,
        deletedJustification,
        deletedBy: ObjectId(userId)
      } }

      await updateItem({ collection, query, action: eventAction })

      removeEventInCalendar(eventData.id)

      if (eventData.extendedProps.participants?.length && eventData.id) {
        sendEmailInstance(eventData, eventData.id, 'delete')
      }
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.event_remove_error'))
    }
  }

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = (data) => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)

  watch(selectedCalendars, () => {
    refetchEvents()
  })

  // ------------------------------------------------
  // instancesFromEmail
  // ------------------------------------------------
  const instancesFromEmail = computed(() => store.state.calendar.instancesFromEmail)

  watch(instancesFromEmail, () => {
    refetchEvents()
  })

  // ------------------------------------------------
  // translate Calendar
  // ------------------------------------------------
  const language = computed(() => i18n.locale)

  watch(language, () => {
    switch (language.value) {
      case "en":
        calendarApi.setOption('locale')
        break;
      case "es":
        calendarApi.setOption('locale', esLocale)
        break;
      default:
        break;
    }
  })

  // ------------------------------------------------
  // update Filters
  // ------------------------------------------------
  const updateFilters = (filters) => {
    locationFilter.value = filters.locationFilter;
    roleFilter.value = filters.roleFilter;
    workerFilter.value = filters.workerFilter;
    monthFilter.value = filters.monthFilter;
    yearFilter.value = filters.yearFilter;
    zoneFilter.value = filters.zoneFilter;
    agencyFilter.value = filters.agencyFilter;
    if (monthFilter.value >= 0 && yearFilter.value && (monthFilter.value !== calendarMonth.value || yearFilter.value !== calendarYear.value)) {
      calendarApi.gotoDate(new Date(yearFilter.value, monthFilter.value, 1))
    }
    else refetchEvents();
  }

  // ------------------------------------------------
  // Instance event handler
  // ------------------------------------------------
  const openInstanceEventHandler = () => {
    isInstance.value = true
    isEventHandlerSidebarActive.value = true
  }

  // ------------------------------------------------
  // Get event border color based on improvements status
  // ------------------------------------------------
  const getBorderColor = (improvements) => {
    if (!improvements?.length) return null

    let hasOverdueImprovements = false
    let hasPendingImprovements = false

    improvements.forEach(i => {
      const dueDateFormatted = i.dueDate ? new Date(`${i.dueDate.slice(0, 10)} 12:00:00`) : null
      if (!i.completed) {
        if (dueDateFormatted < now) hasOverdueImprovements = true
        else hasPendingImprovements = true
      }
    })

    return `border-${hasOverdueImprovements ? 'danger' : hasPendingImprovements ? 'warning' : 'success'}`
  }

  // --------------------------------------------------------------------------------------------------
  // fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // * This function cannot be converted to an async function because the fullCalendar library throws an error
  // --------------------------------------------------------------------------------------------------
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return

    isLoading.value = true

    const firstDayOfCurrentMonth = new Date(yearFilter.value, monthFilter.value, 1)
    const firstDayOfNextMonth = new Date(yearFilter.value, monthFilter.value + 1, 1)

    const initialQuery = {
      client_id: ObjectId(clientId),
      deleted: { $ne: true },
      start: { $gte: firstDayOfCurrentMonth, $lt: firstDayOfNextMonth },
    }

    const finalQuery = {
      $and: [{ $or: [{'extendedProps.calendar': { $in: selectedCalendars.value } }] }]
    }

    if (selectedCalendars.value.includes('En espera')) {
      finalQuery.$and[0].$or[1] = { "confirmation.pending": true }
    } else {
      finalQuery.$and[1] = { $or: [ { confirmation: null }, { "confirmation.pending": { $ne: true } }] }
    }

    if (commitmentFunctionality) {
      finalQuery.$and.push({ $or: [ { isInstance: true }, { isBehaviourEvaluation: true }] })
      if (getUserData.role === 'supervisor') {
        finalQuery.$or = [
          { "organizer._id": userId },
          { "organizer.locations._id": { $in: getUserData.locations?.map(e => e.value) || [] } },
          { participants: ObjectId(userId) }
        ]
      }

      if (locationFilter.value.length > 0) {
        finalQuery.$or[1]['organizer.locations._id'] = { $in: locationFilter.value }
      }  
    } else {
      finalQuery.$or = [
        { "attendee.deleted": { $ne: true } },
        { 
          "attendee.deleted": true,
          "extendedProps.calendar": "Realizadas"
        },
        { attendee: { $exists: false } }
      ]

      if (getUserData.role === 'supervisor') {
        finalQuery.$or[0]["attendee.supervisors"] = ObjectId(userId)
        finalQuery.$or[1]["attendee.supervisors"] = ObjectId(userId)
        finalQuery.$or[2]["organizer.locations._id"] = { $in: getUserData.locations?.map(e => e.value) || [] }
        finalQuery.$or[3] = { "attendee._id": userId }
      }

      if (locationFilter.value.length > 0) {
        finalQuery.$or[0]['attendee.locations._id'] = { $in: locationFilter.value }
        finalQuery.$or[1]['attendee.locations._id'] = { $in: locationFilter.value }
        finalQuery.$or[2]['organizer.locations._id'] = { $in: locationFilter.value }
        if (finalQuery.$or[3]) finalQuery.$or[3]['attendee.locations._id'] = { $in: locationFilter.value }
      }
  
      if (zoneFilter.value.length > 0) {
        finalQuery.$or[0]['attendee.locations.zone'] = { $in: zoneFilter.value }
        finalQuery.$or[1]['attendee.locations.zone'] = { $in: zoneFilter.value }
        finalQuery.$or[2]['organizer.locations.zone'] = { $in: zoneFilter.value }
        if (finalQuery.$or[3]) finalQuery.$or[3]['attendee.locations.zone'] = { $in: zoneFilter.value }
      }
  
      if (agencyFilter.value.length > 0) {
        finalQuery.$or[0]['attendee.locations.agency'] = { $in: agencyFilter.value }
        finalQuery.$or[1]['attendee.locations.agency'] = { $in: agencyFilter.value }
        finalQuery.$or[2]['organizer.locations.agency'] = { $in: agencyFilter.value }
        if (finalQuery.$or[3]) finalQuery.$or[3]['attendee.locations.agency'] = { $in: agencyFilter.value }
      }
    }

    if (roleFilter.value.length > 0) {
      finalQuery['attendee.roles'] = { $in: roleFilter.value.map(e => ObjectId(e)) }
    }

    if (workerFilter.value.length > 0) {
      finalQuery['attendee._id'] = { $in: workerFilter.value }
    }

    const pipeline = [
      { $match: initialQuery },
      { $lookup: { from: 'worker', localField: 'attendee', foreignField: '_id', as: 'attendee', pipeline: [ { $project: { deleted: 1, supervisors: 1, name: 1, locations: 1, roles: 1 } }, { $addFields: { _id: { $toString: "$_id" } } }, { $lookup: { from: 'location', localField: 'locations', foreignField: '_id', as: 'locations', pipeline: [ { $project: { location: 1, zone: 1, agency: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } } ] } },
      { $lookup: { from: 'worker', localField: 'organizer', foreignField: '_id', as: 'organizer', pipeline: [ { $project: { locations: 1 } }, { $addFields: { _id: { $toString: "$_id" } } }, { $lookup: { from: 'location', localField: 'locations', foreignField: '_id', as: 'locations', pipeline: [ { $project: { location: 1, zone: 1, agency: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } } ] } },
      { $lookup: { from: 'confirmation', localField: 'confirmation', foreignField: '_id', as: 'confirmation', pipeline: [ { $project: { pending: 1, supervisor: 1 } }, { $addFields: { _id: { $toString: "$_id" } } }, { $lookup: { from: 'worker', localField: 'supervisor', foreignField: '_id', as: 'supervisor', pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } }, { $addFields: { supervisor: { $arrayElemAt: ["$supervisor", 0] } } } ] } },
      { $addFields: { attendee: { $arrayElemAt: ["$attendee", 0] }, organizer: { $arrayElemAt: ["$organizer", 0] }, confirmation: { $arrayElemAt: ["$confirmation", 0] } } },
      { $match: finalQuery },
      { $lookup: { from: 'process', localField: 'process', foreignField: '_id', as: 'process', pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
      { $lookup: { from: 'commitment', localField: 'commitment', foreignField: '_id', as: 'commitment', pipeline: [ { $project: { completed: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
      { $lookup: { from: 'improvement', localField: 'improvements', foreignField: '_id', as: 'improvements', pipeline: [ { $addFields: { _id: { $toString: "$_id" }, assistance: { $toString: "$assistance" }, dueDate: { $dateToString: { format: "%Y-%m-%dT%H:%M:%SZ", date: "$dueDate" } } } }, { $lookup: { from: 'worker', localField: 'assignee', foreignField: '_id', as: 'assignee', pipeline: [ { $project: { name: 1, email: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } }, { $lookup: { from: 'worker', localField: 'subscribers', foreignField: '_id', as: 'subscribers', pipeline: [ { $project: { _id: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } }, { $addFields: { assignee: { $arrayElemAt: ["$assignee", 0] } } } ] } },
      { $lookup: { from: 'worker', localField: 'participants', foreignField: '_id', as: 'participants', pipeline: [ { $project: { _id: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
      { $addFields: { _id: { $toString: "$_id" }, assistance: { $toString: "$assistance" }, process: { $arrayElemAt: ["$process", 0] }, commitment: { $arrayElemAt: ["$commitment", 0] }, start: { $dateToString: { format: "%Y-%m-%dT%H:%M:%SZ", date: "$start" } }, end: { $dateToString: { format: "%Y-%m-%dT%H:%M:%SZ", date: "$end" } }, modifiedAt: { $dateToString: { format: "%Y-%m-%dT%H:%M:%SZ", date: "$modifiedAt" } } } },
    ]

    getItemsWithAggregate({ collection: 'event', pipeline })
      .then(response => {
        const events = response.map(value => {
          const s = { ...value }
          // eslint-disable-next-line no-prototype-builtins
          if (s.hasOwnProperty('_id')) {
            // eslint-disable-next-line no-underscore-dangle
            s.id = s?._id
            // eslint-disable-next-line no-underscore-dangle
            delete s._id
          }

          // Event Title
          s.title = setEventTitle(s)

          // eslint-disable-next-line no-underscore-dangle
          if (s.confirmation && !s.confirmation.pending) {
            // Add 'url' property to redirect automatically when event is clicked
            // s.url = `/habit/confirmation/view/${s.confirmation._id}`

            if (!s.start) {
              s.start = new Date(parseInt(s.confirmation._id.substring(0, 8), 16) * 1000)
              s.end = new Date(parseInt(s.confirmation._id.substring(0, 8), 16) * 1000 + 3600)
            }
          }

          if (s.participants?.length) {
            s.participants = s.participants.map(e => e._id)
          }

          if (s.improvements?.length) {
            s.improvements.forEach(e => {
              if (e.subscribers?.length) e.subscribers = e.subscribers.map(s => s._id)
            })
          }
          
          return s
        })
        calendarMonth.value = calendarApi?.getDate().getMonth()
        calendarYear.value = calendarApi?.getDate().getFullYear()
        eventsForStats.value = events;
        successCallback(events)
        if (isOnline.value) fetchAndStoreEvents()
      })
      .catch(error => {
        console.log(error)
        if (error?.isStorageError) handleError({ isStorageError: true })
        else handleError({ error, defaultMessage: i18n.t('message.err_calendar_events_list') })  
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    locale: i18n.locale === "es" ? esLocale : null,
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: window.innerWidth < widthBreakpoint.value ? 'listMonth' : commitmentFunctionality ? 'timeGridWeek' : 'dayGridMonth',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    events: fetchEvents,

    // Responsive calendar view
    windowResize: function() {
      calendarApi.changeView(window.innerWidth < widthBreakpoint.value ? 'listMonth' : commitmentFunctionality ? 'timeGridWeek' : 'dayGridMonth')
    },

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 4,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      const extProps = calendarEvent._def.extendedProps
      const bgColor = extProps.isInstance || extProps.attendee?._id === userId
        ? calendarsColor["Propias"]
        : extProps.confirmation?.pending
          ? calendarsColor["enEspera"]
          : calendarsColor[extProps.calendar]

      // Set border color if event has improvements
      const borderColor = commitmentFunctionality ? getBorderColor(extProps.improvements) : null

      // Background Color + Border Color
      return [ bgColor, borderColor ]
    },
    eventClick({ event: clickedEvent, jsEvent }) {
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent)
      if (!isOnline.value) jsEvent.preventDefault()

      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = false
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,

    // Add aditional information to display for each event in calendar
    eventContent({ event }) {
      const totalImprovements = event.extendedProps.improvements?.length
      const isBehaviourEvaluation = event.extendedProps.isBehaviourEvaluation

      let completedImprovements = 0
      event.extendedProps.improvements.forEach(e => {
        if (e.completed) completedImprovements++
      })

      const title = event.extendedProps.isInstance && event.title === 'Seguimiento'
        ? `${i18n.t('message.follow_up')} IA` // 'Seguimiento IA'
        : event.extendedProps.isInstance
          ? (event.extendedProps.metadata?.find(e => e.name === "instance")?.answer || i18n.t('metadata.instance'))
          : event.title // 'Instancia'

      return {
        // The time that was located before the title is extracted
        // ${event.start.getHours()}h --> commitmentFunctionality
        // ${event.start.getHours()}h --> !commitmentFunctionality
        html:
        // For Arauco (commitmentFunctionality), the behavior commitments (isBehaviourEvaluation) are shown on the calendar, otherwise they are not shown.
        `
          <div style='overflow: hidden; text-align: left; display: ${commitmentFunctionality ? 'block' : 'none'} '>
            ${isBehaviourEvaluation
                ? '<span style="font-size: 1rem;">&#9655;</span>'
                : '<span style="font-size: 1.1rem;">&#x1F5D3;</span>'
              }${title}
            <br>
            ${isBehaviourEvaluation ? '<br>'
              : totalImprovements 
                ? `${totalImprovements
                  ? '<span style="font-size: 1rem; padding-left: 0.2rem">&#x2713;</span>'
                  : ''} ${completedImprovements} ${i18n.t('message.of')} ${totalImprovements} comp.`
                : i18n.t('message.no_commitments_short')}
          </div>

          <div style='overflow: hidden; text-align: left; display: ${commitmentFunctionality ? 'none' : 'block'} '>           
            <span style="">             
            ${title}
            </span>
          </div>        
        `
      }
    }
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,
    updateFilters,
    eventsForStats,
    calendarMonth,
    calendarYear,
    isLoading,
    isInstance,
    openInstanceEventHandler,
    commitmentFunctionality,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  }
}
